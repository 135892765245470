import { useContext, useRef, useState } from "react";

import { useRoles } from "hooks";
import { Form, Spinner } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { put } from "utils/DeApi";

export default function AuditToggler({
  label = <h5>Assessment complete</h5>,
  inAuditReport = false,
}) {
  const subscribedPromises = useRef([]);

  const { isCertifier, isContributor } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);

  const [auditStatus, setAuditStatus] = useState(
    audit?.status === "Unfinished" ? false : true
  );
  const [isLoading, setIsLoading] = useState(false);
  const currentAuditStatus =
    audit.status === "Unfinished" ? "In progress" : "Done";

  const updateAuditStatus = () => {
    setAuditStatus((prevStatus) => (prevStatus === false ? true : false));
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const statsPromise = put(`/audits/${audit.auditId}/status`);
    subscribedPromises.current.push(statsPromise);

    statsPromise.promise
      .then(({ data }) => {
        setAudit((prevAudit) => ({
          ...prevAudit,
          status: data.status,
        }));
      })
      .catch((error) => {
        setAuditStatus((prevStatus) => (prevStatus === false ? true : false));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isCertifier || isContributor) return <></>;

  return (
    <div className="d-flex gap-3 align-items-center">
      <div className="flex-fill">
        <Form.Switch
          value={currentAuditStatus}
          checked={auditStatus}
          disabled={isLoading || audit?.locked}
          label={label}
          onChange={updateAuditStatus}
        />
        {!inAuditReport && (
          <p className="text-muted mb-0">
            <small>
              Admins can mark the Assessment as complete, notifying the other
              users that it has been done.
            </small>
          </p>
        )}
      </div>
      {isLoading && (
        <Spinner
          animation="border"
          className="float-end"
          variant="primary"
          size="sm"
        />
      )}
    </div>
  );
}
