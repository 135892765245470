import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

import ItemWithTooltip from "components/ui/ItemWithTooltip";
import { useFetchFacilities } from "features/entity/services";

import "./FacilityTypeahead.scss";

const FacilityTypeahead = ({
  onChange,
  isValid,
  isInvalid,
  selected,
  multiple,
  placeholder = "Select an entity...",
}) => {
  const { isLoading, facilities } = useFetchFacilities({
    perPage: 1000000,
  });
  const [entity, setEntity] = useState([]);

  const handleOnChange = (data) => {
    onChange(data);
    setEntity(data);
  };

  useEffect(() => {
    setEntity(Array.isArray(selected) ? selected : []);
  }, [selected]);

  return (
    <Typeahead
      id="entity"
      name="entity"
      clearButton
      multiple={Boolean(multiple)}
      isLoading={isLoading}
      isValid={Boolean(isValid)}
      isInvalid={Boolean(isInvalid)}
      labelKey={(option) => `${option?.name}`}
      placeholder={placeholder}
      onChange={handleOnChange}
      options={facilities || []}
      selected={entity}
      renderMenu={(results, menuProps) => (
        <Menu className="" {...menuProps}>
          {results.map((result, index) => (
            <MenuItem key={result.facilityId} option={result} position={index}>
              <ItemWithTooltip
                displayText={
                  <div>
                    {result.name}
                    <br />
                    <small className="text-muted">{result.address}</small>
                  </div>
                }
              />
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  );
};

FacilityTypeahead.propTypes = {
  isValid: PropTypes.any,
  selected: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FacilityTypeahead;
